<template>
  <div class="brokerdetail">
    <el-card class="box"
           style="margin-top: 10px;">
    <div>
      <div class="herder_top_main">
        <div class="top_main_hene"></div>
        <div class="heneone">用户详情</div>
      </div>
      <div class="table_box">
        <div class="esal_tal">
          <div class="dids"></div>
          <div class="didses">基本信息</div>
        </div>
        <div class="dis_top">
          <div class="top_left">
            <div class="top_top">
              <div class="left_img">
                <img :src="data.headImgUrl" alt="" v-if="data.headImgUrl">
                <img src="../../../assets/images/profile.png"
                     alt=""  v-else/>
              </div>
            </div>
          </div>
          <div class="top_center">
            <div class="center_font"><div class="text">姓名： </div><span>{{ data.realName }}</span></div>
            <div class="center_font"><div class="text">手机号： </div><span> {{ data.mobile }}</span></div>
            <div class="center_font"><div class="text">邀请码： </div><span>{{ data.invokeCode }}</span></div>
            <div class="center_font"><div class="text">注册时间： </div><span>{{ data.createTime }}</span></div>
          </div>
          <div class="top_right">
            <div class="center_font"><div class="text">当前收入含预估：¥</div><span>{{ data.estimateAmountStr }}</span></div>
            <div class="center_font"><div class="text">累计收入含预估：¥</div><span>{{ data.estimateTotalAmountStr }}</span></div>
            <div class="center_font"><div class="text">当前可提现收入：¥</div><span>{{ data.cashoutAmountStr }}</span></div>
            <div class="center_font"><div class="text">累计提现收入：¥</div><span>{{ data.cashoutTotalAmountStr }}</span></div>
          </div>
        </div>
        <div class="broker-remark"><div class="text">团长简介：</div><span>{{ data.remark }}</span></div>
        <div class="groupradio">
          <el-radio-group v-model="query.enabled">
            <el-radio :label="true">同意</el-radio>
            <el-radio :label="false">拒绝</el-radio>
          </el-radio-group>
          <div class="groupbtn" @click="submit">提交审核</div>
          <div class="goback" @click="$router.push('/group/broker/list')">返回</div>
        </div>
        <div class="table_box"
             style="margin-top: 100px">
          <div class="esal_tal">
            <div class="dids"></div>
            <div class="didses">自提点信息</div>
          </div>
          <div>
            <el-table :data="data.selfDeliveryAddress"
                      border
                      style="width: 1011px; margin-left: 40px; margin-top: 10px"
                      :header-cell-style="tableHeaderColor"
                      size="medium">
              <el-table-column prop="contact"
                               label="姓名"
                               width="120">
              </el-table-column>
              <el-table-column prop="phone"
                               label="手机号"
                               width="135">
              </el-table-column>
              <el-table-column label="详情地址"
                               width="655">
                <template v-slot:default="scope">
                  {{
                    scope.row.province +
                    scope.row.city +
                    scope.row.district +
                    scope.row.address +
                    scope.row.residenceArea
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </el-card>
  </div>
</template>
<script>
import {
  BrokerDetail,BrokerEdit
} from "@/api/group.js";
export default {
  data () {
    return {
      data: [],
      id: "",
      query:{

      }
    };
  },
  created () {
    this.getBrokerDetail();

  },
  methods: {
    // table
    tableHeaderColor ({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #666666;font-weight: 700;";
      }
    },
    //获取团长详情
    getBrokerDetail () {
      let id = this.$route.query;
      BrokerDetail(id).then((res) => {
        this.data = res.data;
        this.query = res.data
      });
    },

    submit(){
      BrokerEdit(this.query).then(res=>{
        if(res.data){
          this.$message.success("修改成功")
        }else{
          this.$message.error("修改失败");
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.brokerdetail{
  width: 100%;
}
.goback{
  margin-left: 30px;
  text-align: center;
  line-height: 40px;
  width: 140px;
  height: 40px;
  // background: #279dfb;
  border-radius: 4px;
  color: #333;
  border: #ccc 1px solid;
}
.broker-remark{
  display: flex;
  margin-top: 20px;
  margin-left: 223px;
  font-size: 14px;
  color: #333;
}
.groupradio {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-left: 222px;
}
.groupbtn {
  margin-left: 80px;
  text-align: center;
  line-height: 40px;
  width: 140px;
  height: 40px;
  background: #279dfb;
  border-radius: 4px;
  color: #fff;
}
.box {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.herder_top_main {
  display: flex;
  width: 100%;
  height: 50px;
  .top_main_hene {
    margin-top: 8px;
    width: 5px;
    height: 20px;
    background-color: #0066ff;
    margin-top: 13px;
  }
  .heneone {
    font-size: 16px;
    line-height: 50px;
    margin-left: 10px;
    font-weight: bold;
  }
}
.table_box {
  margin-top: 50px;
  .esal_tal {
    display: flex;
    .dids {
      margin-left: 20px;
      width: 15px;
      height: 15px;
      background-color: rgba(194, 128, 255, 1);
    }
    .didses {
      margin-left: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .dis_top {
    display: flex;
    align-items: center;
    .top_left {
      .top_top {
        display: flex;
        justify-content: center;
        .left_shanghu {
          font-size: 12px;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
          width: 28px;
          height: 20px;
          margin-left: -40px;
          margin-top: 10px;
          background-color: red;
        }
        .left_img {
          width: 114px;
          height: 114px;
          border-radius: 50%;
          margin-bottom: 10px;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
      }
      margin-left: 50px;
      margin-top: 20px;
      .left_center {
        width: 200px;
        font-size: 15px;
        text-align: center;
        word-wrap: break-word;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /*控制可显示几行文本*/
      }
      .left_bottom {
        width: 67px;
        height: 20px;
        margin-top: 8px;
        margin-left: 65px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        background-color: #ffa94c;
        color: #ffffff;
        border-radius: 50px;
      }
    }
    .top_center {
      margin-top: 20px;
      margin-left: 50px;
      font-size: 14px;

      .center_font {
        display: flex;
        margin-top: 20px;
        .text{
          width: 80px;
          text-align: right;
        }
      }
    }
    .top_right {
      margin-top: 40px;
      margin-left: 100px;
      font-size: 14px;

      .center_font {
        display: flex;
        margin-top: 15px;
        .text{
          width: 180px;
          text-align: right;
        }
        span{
          display: inline-block;
          // text-align: right;
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-table .cell,
.el-table--border .el-table__cell:first-child .cell {
  text-align: center;
}
</style>
